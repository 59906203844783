import React, { useEffect } from "react"
import Layout from "../../components/layout"
import Helmet from "react-helmet"
import SEO from "../../components/seo"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

if (typeof window !== `undefined`) {
  var search = window.location.search.match(/[?&#]afcode=([^?&#]*)[?&#]?/)
}
if (typeof document !== `undefined`) {
  var cc = document.cookie.match(/[;]?serumAfcode=([^;]*)[;]?/)
}
search = search ? search[1] : null
cc = cc ? cc[1] : null

// クエリがなければクッキーを参照
var afcode = search || cc
if (afcode) {
  var now = new Date()
  now.setHours(now.getHours() + 24)
  document.cookie =
    "serumAfcode=" + afcode + ";expires=" + now.toUTCString() + ";"
  afcode = "&afcode=" + afcode
}
afcode = afcode || ""
const cartUrl = process.env.CART_SITE_URL
const quizUrl = cartUrl + "/hotaru/questions?srm=1" + afcode

const assetVersion = "?v=2111091420"

// slick setting
const theorySettings = {
  arrows: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  infinite: true,
  autoplaySpeed: 10000,
  speed: 2500,
  autoplay: true,
}
const colorSettings = {
  arrows: false,
  dots: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  infinite: true,
  autoplaySpeed: 5000,
  speed: 2500,
  autoplay: true,
}

const AboutSerumPage = () => {
  useEffect(() => {
    window.location.href = "/"
  })

  return (
    <Layout
      sectionClasses={"t-serum-about"}
      dataNamespace={"about_serum"}
      quizUrl={quizUrl}
      isHeadLogo={true}
    >
      <SEO
        title="About Serum"
        image={"https://hotaru-personalized.com/images/about/serum/ogp.jpg"}
      />
      <Helmet>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <div className="t-serum">
        <section className="t-serum__mv">
          <picture>
            <source
              media="(max-width:750px)"
              srcSet={"/images/about/serum/mv_sp.jpg" + assetVersion}
            />
            <img src={"/images/about/serum/mv_pc.jpg" + assetVersion} alt="" />
          </picture>
          <div className="t-serum__ttl t-serum__mv__ttl">
            <p className="t-serum__ttl__sub">HOTARU PERSONALIZED</p>
            <h1 className="t-serum__ttl__main">SERUM</h1>
          </div>
        </section>
        <div className="t-serum__inner">
          <section className="t-serum__concept">
            <div className="t-serum__concept__img">
              <img
                src={"/images/about/serum/concept_1.jpg" + assetVersion}
                alt=""
              />
              <div className="t-serum__concept__img__top__layer">
                <img
                  src={"/images/about/serum/concept_2.jpg" + assetVersion}
                  alt=""
                />
              </div>
            </div>
            <div className="t-serum__concept__txt">
              <div className="t-serum__ttl t-serum__concept__txt__ttl">
                <h2 className="t-serum__ttl__main">CONCEPT</h2>
                <span className="t-serum__ttl__sub">SERUM</span>
              </div>
              <p className="t-serum__desc">
                なりたい自分に、また一歩近づこう。
              </p>
              <p className="t-serum__desc">
                ひとりひとりの肌悩みに寄り添い、
                <br />
                美しさをサポートするパーソナライズスキンケア。
              </p>
              <p className="t-serum__desc">
                選べる処方で、思わず触りたくなる肌心地へ。
              </p>
            </div>
          </section>
          <section className="t-serum__theory">
            <div className="t-serum__theory__txt">
              <div className="t-serum__ttl t-serum__theory__txt__ttl">
                <h2 className="t-serum__ttl__main">THEORY</h2>
              </div>
              <p className="t-serum__desc">
                0から1に
                <br />
                言葉ではなく、肌でわかる
              </p>
              <p className="t-serum__desc">
                HOTARU美肌理論に基づき、美容成分を配合。
                <br />
                肌の奥(※)から表面までアプローチ。
                <br />
                (※角質層まで)
              </p>
            </div>
            <div className="t-serum__theory__img">
              <Slider {...theorySettings}>
                <img
                  src={"/images/about/serum/theory_1.jpg" + assetVersion}
                  alt=""
                />
                <img
                  src={"/images/about/serum/theory_2.jpg" + assetVersion}
                  alt=""
                />
                <img
                  src={"/images/about/serum/theory_3.jpg" + assetVersion}
                  alt=""
                />
              </Slider>
            </div>
          </section>
          <section className="t-serum__color">
            <div className="t-serum__ttl">
              <h2 className="t-serum__ttl__main">COLOR</h2>
            </div>
            <div className="t-serum__color__desc">
              <Slider {...colorSettings}>
                <div className="t-serum__color__item">
                  <div className="t-serum__color__item__inner">
                    <div className="t-serum__color__img">
                      <img
                        src={
                          "/images/about/serum/color_brave.jpg" + assetVersion
                        }
                        alt=""
                      />
                    </div>
                    <div className="t-serum__color__txt">
                      <p className="t-serum__color__txt__name">ROSE RED</p>
                      <p className="t-serum__color__txt__ttl__main">
                        「自信と勇気 -Brave-」
                      </p>
                      <p className="t-serum__color__txt__ttl__sub">
                        自信で溢れる、あなたの味方に
                      </p>
                    </div>
                  </div>
                </div>
                <div className="t-serum__color__item">
                  <div className="t-serum__color__item__inner">
                    <div className="t-serum__color__img">
                      <img
                        src={
                          "/images/about/serum/color_sweet.jpg" + assetVersion
                        }
                        alt=""
                      />
                    </div>
                    <div className="t-serum__color__txt">
                      <p className="t-serum__color__txt__name">PINK</p>
                      <p className="t-serum__color__txt__ttl__main">
                        「優しさと癒し -Sweet-」
                      </p>
                      <p className="t-serum__color__txt__ttl__sub">
                        柔らかさ、ほっとする毎日へ
                      </p>
                    </div>
                  </div>
                </div>
                <div className="t-serum__color__item">
                  <div className="t-serum__color__item__inner">
                    <div className="t-serum__color__img">
                      <img
                        src={
                          "/images/about/serum/color_elegance.jpg" +
                          assetVersion
                        }
                        alt=""
                      />
                    </div>
                    <div className="t-serum__color__txt">
                      <p className="t-serum__color__txt__name">PURPLE</p>
                      <p className="t-serum__color__txt__ttl__main">
                        「感性と優雅さ -Elegance-」
                      </p>
                      <p className="t-serum__color__txt__ttl__sub">
                        神秘的、気品を忘れずに
                      </p>
                    </div>
                  </div>
                </div>
                <div className="t-serum__color__item">
                  <div className="t-serum__color__item__inner">
                    <div className="t-serum__color__img">
                      <img
                        src={
                          "/images/about/serum/color_calm.jpg" + assetVersion
                        }
                        alt=""
                      />
                    </div>
                    <div className="t-serum__color__txt">
                      <p className="t-serum__color__txt__name">BLUE</p>
                      <p className="t-serum__color__txt__ttl__main">
                        「真心と信頼 -Calm-」
                      </p>
                      <p className="t-serum__color__txt__ttl__sub">
                        誠実さ、落ち着いた知的な印象へ
                      </p>
                    </div>
                  </div>
                </div>
                <div className="t-serum__color__item">
                  <div className="t-serum__color__item__inner">
                    <div className="t-serum__color__img">
                      <img
                        src={
                          "/images/about/serum/color_hope.jpg" + assetVersion
                        }
                        alt=""
                      />
                    </div>
                    <div className="t-serum__color__txt">
                      <p className="t-serum__color__txt__name">SKY BLUE</p>
                      <p className="t-serum__color__txt__ttl__main">
                        「開放感と期待 -Hope-」
                      </p>
                      <p className="t-serum__color__txt__ttl__sub">
                        透き通るほど、クリアな心で
                      </p>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </section>
          <section className="t-serum__offer">
            <div className="t-serum__offer__img">
              <img
                src={"/images/about/serum/offer.jpg" + assetVersion}
                alt=""
              />
            </div>
            <div className="t-serum__offer__txt">
              <div className="t-serum__offer__ttl">
                <p className="t-serum__offer__ttl__en">
                  HOTARU PERSONALIZED SERUM
                </p>
                <p className="t-serum__offer__ttl__ja">
                  ホタルパーソナライズドセラム
                </p>
              </div>
              <div className="t-serum__offer__desc">
                <div className="t-serum__offer__desc__cycle">
                  ４週間に
                  <br />
                  １回お届け(定期)
                </div>
                <div className="t-serum__offer__desc__price">
                  初回
                  <br />
                  <span>
                    2,834円<small>(税込)</small>
                  </span>
                </div>
              </div>
              <ul className="t-serum__offer__notice">
                <li>通常価格:5,669円(税込)</li>
                <li>2回目以降:5,082円(税込)</li>
                <li>内容量:28g(約1ヶ月分)</li>
                <li>送料無料</li>
                <li>
                  マイページに記載の製造開始日までにお電話で解約のご連絡がない限り、
                  <br className="u-hide-sp" />
                  毎月自動で商品をお届けします。
                </li>
                <li>
                  <span>
                    ご使用方法は
                    <a href="https://sparty.my.site.com/spartyshopfaq/s/topic/0TOBL00000004F04AI/h3%E5%95%86%E5%93%81%E3%81%AB%E3%81%A4%E3%81%84%E3%81%A6">
                      こちら
                    </a>
                  </span>
                </li>
              </ul>
              <div className="t-serum__offer__cta">
                <a href={quizUrl}>購入する</a>
              </div>
            </div>
          </section>
        </div>
      </div>
    </Layout>
  )
}

export default AboutSerumPage
